
import {computed, defineComponent, reactive, toRaw, toRefs, watch} from 'vue';
import avator from '@/components/tools/avator.vue';
import {useRoute} from 'vue-router';
import axios from '@/api/http.js';
import {ElMessage} from "element-plus";
import {ElLoading} from 'element-plus';

interface DataProps {
}

export default defineComponent({
  name: '',
  components: {
    avator,
  },
  props: {
    data: {
      type: Array,
    },
  },
  setup(props, context) {
    const client = localStorage.getItem('eventClient')
    // action图标 （根据client去获取对应文件夹下的图片，所以名字要一致）
    const imgSurvey = require(`../../assets/imgs/${client}/actionIcon/survey-icon.png`)
    const imgSurveyActive = require(`../../assets/imgs/${client}/actionIcon/survey-icon-active.png`)
    const imgAgenda = require(`../../assets/imgs/agendaIcon/add-to-agenda-icon.png`)
    const imgAgendaActive = require(`../../assets/imgs/agendaIcon/remove-from-agenda-icon.png`)
    const imgResources = require(`../../assets/imgs/${client}/actionIcon/resources-icon.png`)
    const imgResourcesActive = require(`../../assets/imgs/${client}/actionIcon/resources-icon-active.png`)
    const imgCalendar = require(`../../assets/imgs/${client}/actionIcon/calendar-icon.png`)
    const imgCalendarActive = require(`../../assets/imgs/${client}/actionIcon/calendar-icon-active.png`)
    const imgConversation = require(`../../assets/imgs/${client}/actionIcon/conversation-icon.png`)
    const imgConversationActive = require(`../../assets/imgs/${client}/actionIcon/conversation-icon-active.png`)
    const isFull = require(`../../assets/imgs/agendaIcon/remove-from-agenda-icon.png`)

    const route = useRoute();
    // const detail = toRaw(props.data);
    // console.log("==== detail=====",detail)
    // console.log("==== assignedAttendees=====",detail['assignedAttendees'])
    // detail: JSON.parse(JSON.stringify(props.data))

    const data = reactive({
      contentTypeIsppt:false,//content type是ppt才显示preview按钮
      dialogVisible: false,
      dialogPreview: false,
      // rateValue: null,
      originRateValue: null,
      showAddToFavorite: true,
      id: route.query.id,
      detail: props.data,
      showImg: true,
      showVideo: false,
      // carouselData: [
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '1-1'},
      //   {image: require("../../assets/imgs/carousel2.jpeg"), id: '1-2'},
      //   {image: require("../../assets/imgs/carousel3.jpeg"), id: '1-3'},
      //   {image: require("../../assets/imgs/carousel3.jpeg"), id: '1-4'},
      //   {image: require("../../assets/imgs/carousel2.jpeg"), id: '2-1'},
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '2-2'},
      //   {image: require("../../assets/imgs/carousel3.jpeg"), id: '2-3'},
      //   {image: require("../../assets/imgs/carousel3.jpeg"), id: '2-4'},
      //   {image: require("../../assets/imgs/carousel3.jpeg"), id: '3-1'},
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '3-2'},
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '3-3'},
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '3-4'},
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '4-1'},
      //   {image: require("../../assets/imgs/carousel1.jpeg"), id: '4-2'},
      // ],
      dialogVideo: false,
    });
    data.originRateValue = props.data["rateValue"];
    const checkContentType = ()=>{
      let length = data.detail["bottomTargetList"].length;
      for(let i = 0; i < length; i++){
        let item = data.detail["bottomTargetList"][i];
        if(item.key=="Content Type:"){
          if(item.value=='pptx'||item.value=='ppt'){
            data.contentTypeIsppt = true;
          }else{
            data.contentTypeIsppt = false;
          }
        }
      }
    }
    checkContentType();
    const carouselDataLength = computed(() => {
      return Math.ceil(data.detail["previewList"].length / 4)
    });
    // 查看当前是否加入了favorite
    // const checkFavorite = () => {
    //   let length = detail['assignedAttendees'].length;
    //   for (let i = 0; i < length; i++) {
    //     let item = detail['assignedAttendees'][i];
    //     let id = JSON.parse(localStorage.getItem('campusInfo')).userId;
    //     console.log("====item =====",item)
    //     console.log("====id =====",id)
    //     if (item == id) {
    //       data.showAddToFavorite = false;
    //       break;
    //     }
    //   }
    // };
    const checkFavorite = () => {
      let length = data.detail['assignedAttendees'].length;
      if (length == 0) {
        data.showAddToFavorite = true;
      } else {
        for (let i = 0; i < length; i++) {
          let item = data.detail['assignedAttendees'][i];
          let id = "";
          if(localStorage.getItem('campusInfo')){
             id = JSON.parse(localStorage.getItem('campusInfo')).userId;
          }
          if (item == id) {
            data.showAddToFavorite = false;
            break;
          } else {
            data.showAddToFavorite = true;
          }
        }
      }
    };
    checkFavorite();
    // 打分
    const clickRate = () => {
      data.dialogVisible = true;
    };
    // 加入/取消 Favorite
    const toggleFavorite = () => {
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'POST',
        url: '/m/updateMyAgenda/',
        data: {
          agendaId: data.id,
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          // meetingId: localStorage.eventsMeetingId,
          isAdd: data.showAddToFavorite ? 1 : 0
        }
      }).then(res => {
        loading.close();
        console.log('res:', res);
        data.showAddToFavorite = !data.showAddToFavorite;
      }).catch(err => {
        console.log(err);
      });
    };
    // 确认打分
    const clickDone = () => {
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'POST',
        url: '/virtual/updateAgendaMark',
        data: {
          agendaId: data.id,
          rateValue: props.data["rateValue"],
        }
      }).then(res => {
        console.log('res:', res);
        data.originRateValue = props.data["rateValue"];
        loading.close();
        // ElMessage({
        //   type: 'success',
        //   message: 'success'
        // });
        data.dialogVisible = false;
      }).catch(err => {
        console.log(err);
        data.dialogVisible = false;
        loading.close();
      })
    };
    // 点击Preview
    const clickPreview = () => {
      data.dialogPreview = true;
    };
    // 点击Preview弹窗里面的图片
    const clickCarouselImg = () => {

    };
    // 点击视频播放
    const clickVideo = () => {
      data.dialogVideo = true;
    }
    const playVideo = () => {
      let video = <HTMLVideoElement>document.getElementById("myVideo");
      video.play();
    }
    const stopVideo = () => {
      let video = <HTMLVideoElement>document.getElementById("myVideo");
      video.pause();
    }
    // 获取当前content的类型，根据类型展示不同界面
    const getContentType = () => {
      // console.log("==== data.detail.contentType=====",props.data["ContentType"])
      if (data.detail["ContentType"] == 'video') {
        data.showVideo = true;
        data.showImg = false;
      } else {
        data.showVideo = false;
        data.showImg = true;
      }
    }
    getContentType();
    const openTab = (url)=>{
      if(url){
        window.open(url)
      }
    }
    const downLoad = () => {
      // window.open('vmWare/fileDownload?id'+data.id);
      axios.request({
        method: 'GET',
        url: '/virtual/fileDownload',
        params: {
          id: data.id
        }
      }).then(res => {
        window.open(res.data.data.fileUrl);
      }).catch(err => {
        console.log(err);
      })
    };
    watch(() => props.data, (newValue, oldVlaue) => {
      data.showImg = false;
      data.detail = newValue;
      setTimeout(() => {
        data.showImg = true;
      }, 500);
      checkFavorite();
    });
    watch(() => data.dialogVideo, (newValue, oldVlaue) => {
      if (!newValue) {
        stopVideo();
      }
    });
    watch(() => data.dialogVisible, (newValue, oldVlaue) => {
      if (!newValue) {
        console.log("====originRateValue =====", data["originRateValue"])
        data.detail["rateValue"] = data["originRateValue"];
      }
    });
    return {
      ...toRefs(data),
      carouselDataLength,//4张图片为一组，将轮播图分为几组
      clickRate,
      clickDone,
      checkFavorite,
      // detail,
      toggleFavorite,
      clickPreview,
      clickCarouselImg,
      clickVideo,
      playVideo,
      stopVideo,
      getContentType,
      downLoad,
      checkContentType,
      client,
      // action icon
      imgSurvey,
      imgSurveyActive,
      imgAgenda,
      imgAgendaActive,
      imgResources,
      imgResourcesActive,
      imgCalendar,
      imgCalendarActive,
      imgConversation,
      imgConversationActive,
      openTab
    };
  },
});
